/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_50 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: rem(24),
      marginBottom: rem(64),
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(600),
      margin: 'auto',
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(42),
      marginBottom: rem(84),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(64),
      marginBottom: rem(92),
    }),
  ),
  title: merge(
    {
      marginBottom: rem(0),
      maxWidth: rem(275),
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: '100%',
    }),
  ),
  paragraph: merge({
    color: NEUTRAL_COLOR_50,
    textAlign: 'center',
    maxWidth: rem(400),
  }),
  button: merge(
    {
      marginTop: rem(4),
      width: '100%',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(12),
    }),
  ),
  image: merge(
    {
      marginTop: rem(54),
      maxWidth: rem(275),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(62),
      maxWidth: rem(350),
    }),
  ),
})

export default styles
