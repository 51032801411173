/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import { Wrapper, Image, Title, Paragraph, Button } from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import styles from './styles'

type Props = {
  code: number,
  url: string,
  name: string,
  title: string,
  description: string,
}

export default function DemonstrationThankTemaplate(props: Props) {
  const { url, code, name, title, description } = props
  return (
    <Layout disableInbound={true} url={url} name={name} title={title}>
      <Wrapper>
        <div className={css(styles.container)}>
          <Image className={css(styles.image)} src="abstractpattern_404page_1.png" />
          <Title level={1} className={css(styles.title)}>
            {String(code)}
          </Title>
          <Title level={2} className={css(styles.description)}>
            {title}
          </Title>
          <Paragraph size="large" className={css(styles.paragraph)}>
            {description}
          </Paragraph>
          <Button className={css(styles.button)} size="huge" to="/">
            Retour à l’accueil
          </Button>
        </div>
      </Wrapper>
    </Layout>
  )
}
