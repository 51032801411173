/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { grid, breakpoints, colors, fonts } from '../../theme'

const { row, column, offset } = grid
const { LIBRE_FRANKLIN_REGULAR } = fonts
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_70, DANGER_COLOR_50 } = colors
const { ARCHIVO_REGULAR } = fonts

const styles = StyleSheet.create({
  container: merge(
    {
      ...row(),
      marginTop: rem(12),
      marginBottom: rem(0),
    },
    media(LARGE_BREAKPOINT, {
      alignItems: 'flex-start',
      marginTop: rem(32),
      marginBottom: rem(80),
    }),
  ),
  content: merge(
    {
      ...column(1),
    },
    media(LARGE_BREAKPOINT, {
      ...column(4.8 / 12),
    }),
  ),
  wrapper: merge(
    media(MEDIUM_BREAKPOINT, {
      ...row(),
    }),
    media(LARGE_BREAKPOINT, {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      marginLeft: 0,
    }),
  ),
  text: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(1),
    }),
    media(LARGE_BREAKPOINT, {
      width: '100%',
      maxWidth: '100%',
      margin: 0,
    }),
  ),
  title: merge(
    {
      maxWidth: rem(400),
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(450),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(500),
    }),
  ),
  description: {
    ...ARCHIVO_REGULAR,
    fontSize: rem(22),
  },
  list: merge(
    {
      marginTop: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(28),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(32),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(36),
    }),
  ),
  form: merge(
    {
      marginTop: rem(12),
      padding: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(36),
      marginBottom: rem(12),
      backgroundColor: 'none',
    }),
    media(LARGE_BREAKPOINT, {
      backgroundColor: NEUTRAL_COLOR_10,
      ...offset(0.2 / 12),
      ...column(7 / 12),
      paddingTop: rem(46),
      paddingBottom: rem(46),
      paddingLeft: rem(60),
      paddingRight: rem(60),
    }),
  ),
  group: merge(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  input: merge(
    {
      flex: 1,
      marginBottom: rem(10),
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(14),
    }),
  ),
  inputWithMargin: merge(
    {},
    media(MEDIUM_BREAKPOINT, {
      marginRight: rem(18),
    }),
  ),
  terms: merge(
    {
      marginTop: rem(12),
      marginBottom: rem(22),
      color: NEUTRAL_COLOR_70,
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(16),
    }),
  ),
  button: merge({
    width: '100%',
  }),
  error: {
    display: 'block',
    marginTop: rem(6),
    marginBottom: rem(6),
    ...LIBRE_FRANKLIN_REGULAR,
    color: DANGER_COLOR_50,
    fontSize: rem(11),
  },
})

export default styles
