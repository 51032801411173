/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import { Wrapper, Title, Paragraph, Button, Image } from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import styles from './styles'

type Props = {
  url: string,
  name: string,
  title: string,
}

export default function DemonstrationThankTemaplate(props: Props) {
  const { url, name, title } = props
  return (
    <Layout disableInbound={true} url={url} name={name} title={title}>
      <Wrapper>
        <div className={css(styles.container)}>
          <Title className={css(styles.title)}>{title}</Title>
          <Paragraph size="large" className={css(styles.paragraph)}>
            Restez près de votre téléphone, nous allons vous appeler sans tarder :)
          </Paragraph>
          <Button className={css(styles.button)} size="huge" to="/">
            Retour à l’accueil
          </Button>
          <Image className={css(styles.image)} src="illu_thankspage_support.png" />
        </div>
      </Wrapper>
    </Layout>
  )
}
