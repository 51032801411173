/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_50 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      marginTop: rem(24),
      marginBottom: rem(64),
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(600),
      margin: 'auto',
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(42),
      marginBottom: rem(84),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(64),
      marginBottom: rem(92),
    }),
  ),
  image: merge(
    {
      position: 'absolute',
      top: 0,
      zIndex: -1,
      width: '100%',
      maxWidth: rem(360),
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'block',
      top: rem(6),
      maxWidth: rem(450),
    }),
    media(LARGE_BREAKPOINT, {
      display: 'block',
      maxWidth: rem(500),
    }),
    media(HUGE_BREAKPOINT, {
      display: 'block',
      top: rem(-6),
      maxWidth: rem(550),
    }),
  ),
  title: merge(
    {
      marginTop: rem(54),
      marginBottom: rem(54),
      maxWidth: rem(275),
      fontSize: rem(64),
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: '100%',
      marginTop: rem(84),
      fontSize: rem(84),
    }),
    media(LARGE_BREAKPOINT, {
      fontSize: rem(84),
    }),
    media(HUGE_BREAKPOINT, {
      fontSize: rem(88),
    }),
  ),
  description: merge(
    {
      marginTop: rem(54),
      marginBottom: rem(2),
      maxWidth: rem(350),
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(500),
      marginTop: rem(64),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(500),
      marginTop: rem(72),
    }),
  ),
  paragraph: merge({
    marginBottom: rem(24),
    color: NEUTRAL_COLOR_50,
    textAlign: 'center',
    maxWidth: rem(400),
  }),
  button: merge(
    {
      marginTop: rem(4),
      width: '100%',
      maxWidth: rem(450),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(12),
      marginBottom: rem(48),
      maxWidth: rem(500),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(600),
    }),
  ),
})

export default styles
