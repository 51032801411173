/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import { Wrapper, Title } from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import styles from './styles'

type Props = {
  url: string,
  name: string,
  title: string,
  children: string,
}

export default function LegalDocumentTemaplate(props: Props) {
  const { url, name, title, children } = props
  return (
    <Layout url={url} name={name} title={title}>
      <Wrapper>
        <div className={css(styles.container)}>
          <Title className={css(styles.title)}>{title}</Title>
          <div className={css(styles.content)} dangerouslySetInnerHTML={{ __html: children }} />
        </div>
      </Wrapper>
    </Layout>
  )
}
