/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before, after } from 'glamor'
import { rem } from 'polished'
import { breakpoints, grid, utils, colors } from './../../theme'

const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { column, row, DEFAULT_GUTTER } = grid
const { generateImagePath } = utils
const { NEUTRAL_COLOR_10 } = colors

const styles = StyleSheet.create({
  hero: merge(
    {
      marginTop: rem(36),
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(72),
      marginBottom: rem(84),
    }),
  ),
  features: merge(
    {
      ...row(),
      marginTop: rem(36),
      marginBottom: rem(2),
      justifyContent: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(54),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(94),
    }),
  ),
  feature: merge(
    { ...column(1), marginBottom: rem(24) },
    media(SMALL_BREAKPOINT, column(1 / 2)),
    media(MEDIUM_BREAKPOINT, column(1 / 4)),
  ),
  calloutCards: merge(
    {
      marginBottom: rem(-24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(-12),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(-32),
    }),
  ),
  calloutCard: merge(
    {
      marginTop: rem(64),
      marginBottom: rem(64),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(72),
      marginBottom: rem(72),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(124),
      marginBottom: rem(124),
    }),
  ),
  fantasyCalloutCard: merge(
    media(LARGE_BREAKPOINT, {
      marginBottom: rem(272),
    }),
  ),
  calloutCardMedia: merge(
    before(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
        backgroundSize: 'contain',
      }),
    ),
    after(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center right',
        backgroundSize: 'contain',
      }),
    ),
  ),
  calloutCardMediaCrater: merge(
    after({
      bottom: rem(-84),
      left: rem(-72),
      width: '100%',
      height: rem(84),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_cashing_bottom.png',
      )})`,
    }),
  ),
  calloutCardMediaMexicali: merge(
    before({
      left: rem(-24),
      height: '100%',
      width: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_clientinfo_left.png',
      )})`,
    }),
    after({
      top: rem(-96),
      left: rem(72),
      width: '100%',
      height: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_clientinfo_top.png',
      )})`,
    }),
  ),
  calloutCardMediaLanding: merge(
    after({
      bottom: rem(-84),
      left: rem(-72),
      width: '100%',
      height: rem(84),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_products_bottom.png',
      )})`,
    }),
  ),
  calloutCardMediaClinch: merge(
    before({
      left: rem(-24),
      height: '100%',
      width: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_activities_left.png',
      )})`,
    }),
  ),
  calloutCardMediaMujeres: merge(
    before({
      left: rem(-166),
      height: '100%',
      width: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_clientmonitoring_left.png',
      )})`,
    }),
  ),
  calloutCardMediaGypsyeyes: merge(
    before({
      top: rem(-96),
      left: rem(72),
      width: '100%',
      height: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_createinvoice_top.png',
      )})`,
    }),
    after({
      bottom: rem(-84),
      left: rem(-72),
      width: '100%',
      height: rem(84),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_createinvoice_bottom.png',
      )})`,
    }),
  ),
  calloutCardMediaQuarantine: merge(
    after({
      bottom: rem(-84),
      left: rem(-72),
      width: '100%',
      height: rem(84),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_paiementmonitoring_bottom.png',
      )})`,
    }),
  ),
  calloutCardMediaAlwadood: merge(
    after({
      right: rem(-166),
      height: '100%',
      width: rem(96),
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_purchasemanagement_right.png',
      )})`,
    }),
  ),
  informationInserts: merge(
    {
      marginTop: rem(84),
      marginBottom: rem(54),
      ...row(),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(132),
      marginBottom: rem(112),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(-92),
      marginBottom: rem(148),
    }),
  ),
  informationInsert: merge(
    { ...column(1), marginBottom: rem(24) },
    media(MEDIUM_BREAKPOINT, column(1 / 2)),
  ),
  winobox: merge(
    {
      marginTop: rem(88),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(112),
      marginBottom: rem(84),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(-108),
      marginBottom: rem(180),
    }),
  ),
  businessContainer: merge(
    {
      position: 'relative',
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(112),
      marginBottom: rem(84),
    }),
    before({
      content: ' ',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundImage: `url(${generateImagePath('background_home_winobox_mobile@2x.png')})`,
      backgroundSize: 'contain',
      borderRadius: rem(4),
      zIndex: -1,
    }),
    media(
      MEDIUM_BREAKPOINT,
      before({
        left: rem(DEFAULT_GUTTER / 2),
        width: `calc(100% - ${rem(DEFAULT_GUTTER)})`,
        backgroundImage: `url(${generateImagePath('background_home_winobox_desktop@2x.png')})`,
      }),
    ),
    media(
      LARGE_BREAKPOINT,
      before({
        backgroundImage: `url(${generateImagePath('background_home_winobox_desktop@2x.png')})`,
      }),
    ),
    media(
      LARGE_BREAKPOINT,
      after({
        content: ' ',
        display: 'block',
        position: 'absolute',
        top: rem(DEFAULT_GUTTER * -3),
        left: rem(DEFAULT_GUTTER * -3),
        width: `calc(100% + ${rem(DEFAULT_GUTTER * 6)})`,
        height: `calc(100% + ${rem(DEFAULT_GUTTER * 6)})`,
        background: NEUTRAL_COLOR_10,
        borderRadius: rem(4),
        zIndex: -2,
      }),
    ),
  ),
  businessContent: merge(
    {
      padding: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      padding: rem(44),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
    }),
  ),
  businessMedia: merge(
    {
      borderRadius: rem(4),
      overflow: 'hidden',
    },
    media(MEDIUM_BREAKPOINT, {
      height: '100%',
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  ),
  tinySupport: media(LARGE_BREAKPOINT, {
    marginTop: rem(-98),
  }),
})

export default styles
