/* @flow */

import * as Sentry from '@sentry/gatsby'
import { navigate } from 'gatsby'
import { css } from 'glamor'
import * as React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import {
  Button,
  DemoCard,
  List,
  ListItem,
  Paragraph,
  TextInput,
  Title,
  Wrapper,
} from '../../components'
import constants from '../../constants'
import { DefaultLayout as Layout } from '../../layouts'
import { submitQuoteForm } from '../../utils/hubspot'
import styles from './styles'

const { FIRST_NAME, LAST_NAME, COMPANY_NAME, EMAIL, PHONE, RECAPTCHA } = constants.form

type Values = {
  [name: string]: ?string,
}

type Errors = {
  [name: string]: ?string,
}

function validateField(name: string, value: ?string) {
  switch (name) {
    case FIRST_NAME:
    case LAST_NAME:
    case COMPANY_NAME:
    case PHONE:
      return (!value || String(value).length === 0) && 'Champs requis'
    case EMAIL:
      if (!value || String(value).length === 0) {
        return 'Champs requis'
      }
      return String(value).indexOf('@') === -1 && 'Email non valide'
    case RECAPTCHA:
      return (
        (!value || String(value).length === 0) && "Veuillez vérifier que vous n'êtes pas un robot"
      )
    default:
      return ''
  }
}

function validateFields(values: Values) {
  return Object.keys(values).reduce(
    (acc, key) => ({
      ...acc,
      [key]: validateField(key, values[key]),
    }),
    {},
  )
}

function hasErrors(errors: Errors) {
  return Object.values(errors).some(error => typeof error === 'string' && error.length)
}

type Props = {
  url: string,
  name: string,
  title: string,
  description: string,
}

type State = {|
  values: Values,
  errors: Errors,
  submitCount: number,
  submitting: boolean,
|}

export default class DevisFormTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const values = {
      [FIRST_NAME]: '',
      [LAST_NAME]: '',
      [COMPANY_NAME]: '',
      [EMAIL]: '',
      [PHONE]: '',
      [RECAPTCHA]: '',
    }
    const errors = validateFields(values)
    const submitCount = 0
    const submitting = false
    this.state = { values, errors, submitCount, submitting }
  }

  onChangeField = (name: string, value: string) => {
    this.setState(prevState => {
      return {
        values: {
          ...prevState.values,
          [name]: value || '',
        },
        errors: {
          ...prevState.errors,
          [name]: validateField(name, value),
        },
      }
    })
  }

  onRecaptchaChangefield = (value: string) => {
    this.setState(prevState => {
      return {
        values: {
          ...prevState.values,
          [RECAPTCHA]: value || '',
        },
        errors: {
          ...prevState.errors,
          [RECAPTCHA]: validateField(RECAPTCHA, value),
        },
      }
    })
  }

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      if (this.state.submitting) {
        return
      }
      this.setState(prevState => ({ submitting: true }))
      const { values, errors } = this.state

      if (!hasErrors(errors)) {
        await submitQuoteForm({
          firstName: values[FIRST_NAME],
          lastName: values[LAST_NAME],
          email: values[EMAIL],
          company: values[COMPANY_NAME],
          phone: values[PHONE],
        })

        navigate('/merci/')
      }
    } catch (err) {
      Sentry.captureException(err, { extra: { state: this.state } })
    } finally {
      this.setState(prevState => ({
        submitCount: prevState.submitCount + 1,
        submitting: false,
      }))
    }
  }

  render() {
    const { url, name, title, description } = this.props
    const { values, errors, submitCount, submitting } = this.state

    return (
      <Layout
        disableInbound={true}
        url={url}
        name={name}
        title={title}
        description={description}
        enableMinimalistNav={true}
      >
        <Wrapper>
          <div className={css(styles.container)}>
            <div className={css(styles.content)}>
              <div className={css(styles.wrapper)}>
                <div className={css(styles.text)}>
                  <Title className={css(styles.title)}>{description}</Title>
                  <span className={css(styles.description)}>
                    Remplissez le formulaire et notre équipe vous contactera pour vous présenter nos
                    différentes offres commerciales, vous conseiller et définir avec vous l'offre la
                    plus adaptée à votre cave ou à votre épicerie fine.
                  </span>
                  <List className={css(styles.list)} title="La marche à suivre :">
                    <ListItem>Remplissez le formulaire</ListItem>
                    <ListItem>Un conseiller vous rappelle sous 24h du lundi au vendredi</ListItem>
                  </List>
                </div>
              </div>
            </div>
            <form id="devis-form" className={css(styles.form)} onSubmit={this.onSubmit}>
              <div className={css(styles.group)}>
                <TextInput
                  className={css(styles.input, styles.inputWithMargin)}
                  id="firstName"
                  size="large"
                  label="Prénom*"
                  name={FIRST_NAME}
                  value={values[FIRST_NAME]}
                  error={Boolean(submitCount) && errors[FIRST_NAME]}
                  submitted={Boolean(submitCount)}
                  onChange={this.onChangeField}
                />
                <TextInput
                  className={css(styles.input)}
                  id="lastName"
                  size="large"
                  label="Nom*"
                  name={LAST_NAME}
                  value={values[LAST_NAME]}
                  error={Boolean(submitCount) && errors[LAST_NAME]}
                  submitted={Boolean(submitCount)}
                  onChange={this.onChangeField}
                />
              </div>
              <TextInput
                className={css(styles.input)}
                id="company"
                size="large"
                label="Nom de l'entreprise*"
                name={COMPANY_NAME}
                value={values[COMPANY_NAME]}
                error={Boolean(submitCount) && errors[COMPANY_NAME]}
                submitted={Boolean(submitCount)}
                onChange={this.onChangeField}
              />
              <div className={css(styles.group)}>
                <TextInput
                  className={css(styles.input, styles.inputWithMargin)}
                  id="email"
                  size="large"
                  label="Email*"
                  name={EMAIL}
                  value={values[EMAIL]}
                  error={Boolean(submitCount) && errors[EMAIL]}
                  submitted={Boolean(submitCount)}
                  onChange={this.onChangeField}
                />
                <TextInput
                  className={css(styles.input)}
                  id="phone"
                  size="large"
                  label="Téléphone*"
                  name={PHONE}
                  value={values[PHONE]}
                  error={Boolean(submitCount) && errors[PHONE]}
                  submitted={Boolean(submitCount)}
                  onChange={this.onChangeField}
                />
              </div>
              <Paragraph className={css(styles.terms)} size="tiny">
                Wino a besoin des coordonnées que vous nous fournissez pour vous contacter au sujet
                de nos produits et services. Vous pouvez vous désabonner de ces communications à
                tout moment. Pour en savoir plus sur nos modalités de désabonnement, sur nos
                pratiques en matière de confidentialité et sur notre engagement vis-à-vis de la
                protection de la vie privée, consultez notre Politique de confidentialité.
              </Paragraph>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  marginBottom: '1.375rem',
                }}
              >
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  hl="fr"
                  onChange={this.onRecaptchaChangefield}
                />
                {Boolean(submitCount) &&
                  errors['recaptcha'] && (
                    <span className={css(styles.error)}>{errors['recaptcha']}</span>
                  )}
              </div>
              <Button id="submit" size="large" disabled={submitting} className={css(styles.button)}>
                {submitting ? 'Envoi du formulaire ...' : 'Parler à un expert'}
              </Button>
            </form>
          </div>
          <DemoCard
            title="Vous souhaitez planifier dès maintenant une démonstration du logiciel de caisse"
            subtitle="Notre équipe se tient à votre disposition pour vous accompagner dans votre projet"
            buttonText="Demander une démo"
            buttonLink="/demander-une-demo"
          />
        </Wrapper>
      </Layout>
    )
  }
}
