/* @flow */

import React from 'react'
import { css, merge } from 'glamor'
import slugify from 'slugify'
import moment from 'moment'

import { Wrapper, Title, Paragraph, Button, Post } from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import styles from './styles'
import articleStyles from '../Article/styles'
import blogStyles from '../Blog/styles'

type Props = {
  url: string,
  name: string,
  title: string,
  dlUrl: string,
  formName: string,
  suggestions: [any],
}

export default class DownloadThankTemaplate extends React.Component<Props> {
  componentDidMount() {
    this.downloadFile()
  }

  getFilename = () => {
    const { dlUrl, formName } = this.props
    const filename = formName.replace(/\ /g, '-')
    const [extension] = dlUrl.split('.').slice(-1)
    return `${filename}.${extension}`
  }

  downloadFile = async () => {
    const { dlUrl } = this.props
    try {
      const res = await fetch(dlUrl)
      const blob = await res.blob()
      const objUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = objUrl

      a.download = this.getFilename()
      // $FlowFixMe
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(objUrl)
      a.remove()
    } catch (err) {
      window.alert("Une erreur s'est produite, veuillez ressayer")
    }
  }

  reduceSummary(summary: ?string) {
    if (!summary) return ''
    const words = summary.replace(/<(?:.|\n)*?>/gm, '')
    if (words.split(' ').length > 30) {
      return `${words
        .split(' ')
        .slice(0, 30)
        .join(' ')} ...`
    }
    return words
  }

  render() {
    const { url, name, title, suggestions } = this.props

    return (
      <Layout disableInbound={true} url={url} name={name} title={title}>
        <Wrapper className={css(styles.container)}>
          <Title className={css(styles.title)}>Merci pour votre téléchargement !</Title>
          <Paragraph>
            Si le téléchargement n’a pas fonctionné, cliquez sur le bouton de{' '}
            <br className={css(styles.none)} />
            téléchargement ci-dessous pour obtenir la ressource.{' '}
          </Paragraph>
          <Button
            to=""
            onClick={e => {
              e.preventDefault()
              this.downloadFile()
            }}
            appearance="light"
            className={css(styles.button)}
          >
            Télécharger maintenant
          </Button>
          <Button to="/blog" className={css(styles.button)} appearance="dark">
            Revenir au blog
          </Button>
          <div
            className={merge(css(articleStyles.relativePostContainer), css(styles.relativePost))}
          >
            <Title className={css(articleStyles.relativePostTitle)}>
              D’autres articles pourraient vous intéresser
            </Title>

            <div className={merge(css(articleStyles.relativePost), css(styles.textLeft))}>
              {suggestions.map((post, i) => {
                return (
                  <Post
                    key={slugify(post.uid)}
                    className={css(blogStyles.post)}
                    to={post.uid}
                    media={post.data.illustration.url}
                    meta={`${moment(post.first_publication_date).format('D MMMM')}`}
                    categorie={post.data.categories}
                    title={post.data.title_post.text}
                    excerpt={this.reduceSummary(post.data.meta_description.text)}
                  />
                )
              })}
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}
