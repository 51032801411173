/* @flow */

import React from 'react'
import { css, merge } from 'glamor'
import { CalloutCard } from './../../components'
import styles from './styles'

type Props = {
  className?: string,
  hideBorder?: boolean,
  title: string,
  media: string,
  content: string,
  buttonText?: string,
  buttonLink?: string,
}

export default function WinoBox(props: Props) {
  const { className, hideBorder, title, media, content, buttonText, buttonLink } = props
  return (
    <CalloutCard
      className={merge(
        css(styles.container, hideBorder && styles.containerWithoutBorder),
        className,
      )}
      contentClassName={css(styles.content)}
      columnMediaClassName={css(styles.columnMedia)}
      mediaClassName={css(styles.media)}
      mediaSize="large"
      direction="rtl"
      appearance="light"
      media={media}
      title={title}
      content={content}
      buttonText={buttonText}
      buttonLink={buttonLink}
    />
  )
}
