/* @flow */

import * as React from 'react'
import { css } from 'glamor'

import { auth } from '@wino/sdk'

import { Wrapper, Title, Paragraph, Button, Image, TextInput } from '../../components'
import { DefaultLayout as Layout } from '../../layouts'
import constants from '../../constants'
import styles from './styles'

const { CODE } = constants.form

type Values = {
  [name: string]: ?string,
}

type Errors = {
  [name: string]: ?string,
}

function validateField(name: string, value: ?string) {
  switch (name) {
    case CODE:
      return /\d{6}/.test(String(value)) ? '' : 'Code à 6 chiffres requis'
    default:
      return ''
  }
}

function validateFields(values: Values) {
  return Object.keys(values).reduce(
    (acc, key) => ({
      ...acc,
      [key]: validateField(key, values[key]),
    }),
    {},
  )
}

function hasErrors(errors: Errors) {
  return Object.values(errors).some(error => typeof error === 'string' && error.length)
}

type Props = {
  url: string,
  name: string,
  title: string,
  redirectUri: string,
  requestId: string,
  description: string,
}

type State = {|
  values: Values,
  errors: Errors,
  submitSuccess: boolean,
  submitError: boolean,
  submitCount: number,
  submitLoading: boolean,
|}

export default class VerifyTemaplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const values = { [CODE]: '' }
    const errors = validateFields(values)
    this.state = {
      errors,
      values,
      submitCount: 0,
      submitError: false,
      submitLoading: false,
      submitSuccess: false,
    }
  }

  onChangeField = (name: string, value: string) => {
    this.setState(prevState => {
      return {
        values: { ...prevState.values, [name]: value || '' },
        errors: { ...prevState.errors, [name]: validateField(name, value) },
      }
    })
  }

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      const { values, errors, submitLoading } = this.state
      if (hasErrors(errors) || submitLoading) {
        throw Error('Some errors')
      }
      this.setState({ submitLoading: true })
      const { requestId, redirectUri } = this.props
      event.preventDefault()
      const authArguments = [
        void 0,
        {
          serviceRootUrl:
            window.location.hostname !== 'wino.fr'
              ? process.env.GATSBY_STAGGING_SERVICE_ROOT_URL
              : process.env.GATSBY_SERVICE_ROOT_URL,
        },
      ]
      const oauth = await auth(...authArguments).authorize(requestId, values[CODE])
      this.setState({ submitSuccess: true })
      window.location.replace(
        `${redirectUri}?access_token=${oauth.access_token}&refresh_token=${oauth.refresh_token}`,
      )
    } catch (err) {
      this.setState({ submitError: true })
    } finally {
      this.setState(prevState => ({
        submitCount: prevState.submitCount + 1,
        submitLoading: false,
      }))
    }
  }

  render() {
    const { url, name, title, description } = this.props
    const { values, errors, submitCount, submitError, submitLoading, submitSuccess } = this.state
    return (
      <Layout
        disableInbound={true}
        enableMinimalistNav={true}
        enableMinimalistFooter={true}
        url={url}
        name={name}
        title={title}
      >
        <Wrapper>
          <form onSubmit={this.onSubmit}>
            <div className={css(styles.container)}>
              <Title className={css(styles.title)}>{title}</Title>
              <Paragraph size="large" className={css(styles.paragraph)}>
                {description}
              </Paragraph>
              <TextInput
                className={css(styles.input)}
                size="large"
                label="Votre code de vérification"
                name={CODE}
                placeholder="******"
                value={values[CODE]}
                error={Boolean(submitCount) && errors[CODE]}
                submitted={Boolean(submitCount)}
                onChange={this.onChangeField}
              />
              <Button
                disabled={submitLoading || hasErrors(errors)}
                className={css(styles.button)}
                size="huge"
              >
                {submitSuccess
                  ? 'Succès !'
                  : submitLoading
                    ? 'Chargement ...'
                    : submitError
                      ? '  Échec, réessayer à nouveau'
                      : "S'identifier en toute sécurité"}
              </Button>
              <Image className={css(styles.image)} src="illu_thankspage_support.png" />
            </div>
          </form>
        </Wrapper>
      </Layout>
    )
  }
}
