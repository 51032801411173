/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import {
  Wrapper,
  CalloutCard,
  EmailForm,
  Feature,
  Feedback,
  InformationInsert,
} from './../../components'
import { WinoBox, Support } from './../../bundles'
import { DefaultLayout as Layout } from './../../layouts'
import styles from './styles'

type FeatureProps = {
  media: string,
  alt?: string,
  title: string,
  content: string,
}

type CalloutCardProps = {
  decoration: string,
  label: string,
  title: string,
  content: string,
  media: string,
  alt?: string,
  feedback: {
    profile: string,
    name: string,
    company: string,
    content: string,
  },
}

type InformationInsertProps = {
  media: string,
  title: string,
  content: string,
  linkText?: string,
  linkTo?: string,
  href?: string,
  target?: string,
}

type Props = {
  url: string,
  name: string,
  media: string,
  label: string,
  title: string,
  description: string,
  body: Array<{
    name: string,
    items?: Array<FeatureProps | CalloutCardProps | InformationInsertProps>,
  }>,
}

export default function ProductTemplate(props: Props) {
  const { url, name, media, label, title, description, body } = props
  return (
    <Layout url={url} name={name} title={title} description={description}>
      <div>
        <Wrapper>
          <CalloutCard
            className={css(styles.hero)}
            size="large"
            label={label}
            alt={name}
            media={media}
            mediaSize="huge"
            mediaLazy={false}
            content={description}
            title={title}
          >
            <EmailForm
              inputPlaceholder="Votre adresse mail"
              submitButtonLink="/demander-une-demo"
              submitButtonText="Faire une démo"
            />
          </CalloutCard>
          {body.map((section, sectionIndex) => {
            switch (section.name) {
              case 'features':
                return (
                  <div key={`${sectionIndex}-${section.name}`} className={css(styles.features)}>
                    {Array.isArray(section.items) &&
                      section.items.map((item, index) => {
                        const feature: FeatureProps = { ...item }
                        return (
                          <Feature
                            key={index}
                            className={css(styles.feature)}
                            align="center"
                            justify-content="center"
                            media={feature.media}
                            alt={feature.alt}
                            title={feature.title}
                            content={feature.content}
                          />
                        )
                      })}
                  </div>
                )
              case 'informationInsert':
                return (
                  <div
                    key={`${sectionIndex}-${section.name}`}
                    className={css(styles.informationInserts)}
                  >
                    {Array.isArray(section.items) &&
                      section.items.map((item, index) => {
                        const informationInsert: InformationInsertProps = { ...item }
                        return (
                          <InformationInsert
                            key={index}
                            className={css(styles.informationInsert)}
                            {...informationInsert}
                            color={index % 2 ? 'secondary' : 'primary'}
                          />
                        )
                      })}
                  </div>
                )
              case 'calloutCards': {
                return (
                  <div key={`${sectionIndex}-${section.name}`} className={css(styles.calloutCards)}>
                    {Array.isArray(section.items) &&
                      section.items.map((item, index, arr) => {
                        // $FlowFixMe
                        const calloutCard: CalloutCardProps = { ...item }
                        return (
                          <CalloutCard
                            key={`${index}-${sectionIndex}-${section.name}`}
                            className={css(
                              styles.calloutCard,
                              (index % 2 || arr.length === 1) && styles.fantasyCalloutCard,
                            )}
                            appearance={index % 2 ? 'fantasy' : 'light'}
                            direction={index % 2 ? 'ltr' : 'rtl'}
                            label={calloutCard.label}
                            title={calloutCard.title}
                            content={calloutCard.content}
                            media={calloutCard.media}
                            alt={calloutCard.alt}
                            mediaSize="small"
                            mediaElevation={true}
                            columnMediaClassName={css(
                              styles.calloutCardMedia,
                              calloutCard.decoration === 'crater' && styles.calloutCardMediaCrater,
                              calloutCard.decoration === 'mexicali' &&
                              styles.calloutCardMediaMexicali,
                              calloutCard.decoration === 'landing' &&
                              styles.calloutCardMediaLanding,
                              calloutCard.decoration === 'clinch' && styles.calloutCardMediaClinch,
                              calloutCard.decoration === 'mujeres' &&
                              styles.calloutCardMediaMujeres,
                              calloutCard.decoration === 'gypsyeyes' &&
                              styles.calloutCardMediaGypsyeyes,
                              calloutCard.decoration === 'quarantine' &&
                              styles.calloutCardMediaQuarantine,
                              calloutCard.decoration === 'alwadood' &&
                              styles.calloutCardMediaAlwadood,
                            )}
                          >
                            {calloutCard.feedback && (
                              <Feedback
                                authorImage={calloutCard.feedback.profile}
                                authorName={calloutCard.feedback.name}
                                authorCompany={calloutCard.feedback.company}
                                content={calloutCard.feedback.content}
                              />
                            )}
                          </CalloutCard>
                        )
                      })}
                  </div>
                )
              }
              case 'winobox': {
                return <WinoBox
                  key="winobox"
                  className={css(styles.winobox)}
                  hideBorder={true} title={section.title}
                  media={section.media} content={section.content}
                  buttonText={section.buttonText}
                  buttonLink={section.buttonLink}
                />
              }
              default:
                return null
            }
          })}
          <Support
            className={css(!body.find(section => section.name === 'winobox') && styles.tinySupport)}
          />
        </Wrapper>
      </div>
    </Layout>
  )
}
