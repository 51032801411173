/* @flow */

import React from 'react'
import { Support as PresentationalSupport } from './../../components'

type Props = {
  className?: string,
}

export default class Support extends React.Component<Props> {
  handleShowIntercom = (event: SyntheticEvent<>) => {
    event.preventDefault()
    if (typeof window !== 'undefined' && 'Intercom' in window) {
      window.Intercom('show')
    }
  }

  render() {
    const { className } = this.props
    return (
      <PresentationalSupport
        className={className}
        title="L'équipe support qui vous répond plus vite que son ombre !"
        content="Avec un service client disponible tous les jours via l'application, vous êtes accompagnés au quotidien. Nos équipes connaissant les métiers de caviste et d’épicerie sur le bout des doigts. Notre engagement : une réponse en cinq minutes top chrono ! 
        "
        buttonText="Contacter le support client"
        buttonOnClick={this.handleShowIntercom}
      />
    )
  }
}
