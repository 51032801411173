import axios from 'axios'
import Cookies from 'js-cookie'

import constants from '../constants'

function hubspotField({ objectTypeId, name, value }) {
  return {
    objectTypeId,
    name,
    value,
  }
}

const firstNameField = value => hubspotField({ objectTypeId: '0-1', name: 'firstname', value })
const lastNameField = value => hubspotField({ objectTypeId: '0-1', name: 'lastname', value })
const emailField = value => hubspotField({ objectTypeId: '0-1', name: 'email', value })
const companyField = value => hubspotField({ objectTypeId: '0-2', name: 'name', value })
const phoneField = value => hubspotField({ objectTypeId: '0-1', name: 'phone', value })

async function submitForm({ fields, formId }) {
  const { PORTAL_ID, SUBMIT_WEBFORM_URL } = constants.hubspot

  const hubspotutk = Cookies.get('hubspotutk')

  await axios({
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    url: `${SUBMIT_WEBFORM_URL}/${PORTAL_ID}/${formId}`,
    data: JSON.stringify({
      submittedAt: Date.now(),
      fields,
      context: {
        hutk: hubspotutk,
        pageUri: document.URL,
      },
      legalConsentOptions: {
        // Note:
        // all forms has the same concent, if that ever changes, please change this
        consent: constants.hubspot.consent(true),
      },
    }),
  })
}

export const submitDemoForm = ({ firstName, lastName, email, company, phone }) =>
  submitForm({
    fields: [
      firstNameField(firstName),
      lastNameField(lastName),
      emailField(email),
      companyField(company),
      phoneField(phone),
    ],
    formId: constants.hubspot.forms.demo.FORM_ID,
  })

export const submitQuoteForm = ({ firstName, lastName, email, company, phone }) =>
  submitForm({
    fields: [
      firstNameField(firstName),
      lastNameField(lastName),
      emailField(email),
      companyField(company),
      phoneField(phone),
    ],
    formId: constants.hubspot.forms.quote.FORM_ID,
  })

export const submitContactForm = ({ firstName, lastName, email, company, phone }) =>
  submitForm({
    fields: [
      firstNameField(firstName),
      lastNameField(lastName),
      emailField(email),
      companyField(company),
      phoneField(phone),
    ],
    formId: constants.hubspot.forms.contact.FORM_ID,
  })
