/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before, after } from 'glamor'
import { rem } from 'polished'
import { breakpoints, grid, colors } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { column, DEFAULT_GUTTER } = grid
const { NEUTRAL_COLOR_10 } = colors

const styles = StyleSheet.create({
  container: merge(
    {
      position: 'relative',
      marginTop: rem(46),
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(112),
      marginBottom: rem(84),
    }),
    before({
      content: ' ',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: rem(4),
      backgroundColor: NEUTRAL_COLOR_10,
      zIndex: -1,
    }),
    media(
      MEDIUM_BREAKPOINT,
      before({
        left: rem(DEFAULT_GUTTER / 2),
        width: `calc(100% - ${rem(DEFAULT_GUTTER)})`,
      }),
    ),
    media(
      LARGE_BREAKPOINT,
      after({
        content: ' ',
        display: 'block',
        position: 'absolute',
        top: rem(DEFAULT_GUTTER * -3),
        left: rem(DEFAULT_GUTTER * -3),
        width: `calc(100% + ${rem(DEFAULT_GUTTER * 6)})`,
        height: `calc(100% + ${rem(DEFAULT_GUTTER * 6)})`,
        borderRadius: rem(4),
        zIndex: -2,
      }),
    ),
  ),
  containerWithoutBorder: merge(
    media(
      LARGE_BREAKPOINT,
      after({
        content: ' ',
        display: 'none',
      }),
    ),
  ),
  content: merge(
    {
      padding: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      padding: rem(56),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
    }),
  ),
  columnMedia: merge(
    {
      borderRadius: rem(4),
      overflow: 'hidden',
    },
    media(MEDIUM_BREAKPOINT, {
      height: '100%',
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
  ),
  media: merge(
    media(MEDIUM_BREAKPOINT, {
      width: 'auto',
    }
    )),
})

export default styles
