/* @flow */

export { default as ArticleTemplate } from './Article'
export { default as BlogTemplate } from './Blog'
export { default as ContactFormTemplate } from './ContactForm'
export { default as DemonstrationFormTemplate } from './DemonstrationForm'
export { default as DemonstrationThankTemplate } from './DemonstrationThank'
export { default as DevisFormTemplate } from './DevisForm'
export { default as DownloadThankTemplate } from './DownloadThank'
export { default as ErrorTemplate } from './Error'
export { default as HomepageTemplate } from './Homepage'
export { default as LegalDocumentTemplate } from './LegalDocument'
export { default as PayCallbackTemplate } from './PayCallback'
export { default as ProductTemplate } from './Product'
export { default as VerifyTemplate } from './Verify'
