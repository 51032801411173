/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import { Wrapper, Title, Paragraph, Button, Image } from '../../components'
import { DefaultLayout as Layout } from '../../layouts'
import styles from './styles'

type Props = {
  url: string,
  name: string,
  logo?: string,
  title: string,
  description: string,
  callbackText?: string,
  callbackLink?: string,
  brandText?: string,
  brandInformations?: string,
}

export default function PayCallbackTemaplate(props: Props) {
  const {
    url,
    name,
    logo,
    title,
    description,
    callbackText,
    callbackLink,
    brandText,
    brandInformations,
  } = props
  return (
    <Layout
      disableInbound={true}
      enableMinimalistNav={true}
      enableMinimalistFooter={true}
      url={url}
      name={name}
      title={title}
      brandText={brandText}
      brandInformations={brandInformations}
    >
      <Wrapper>
        <div className={css(styles.container)}>
          {!!logo && <Image className={styles.logo} src={logo} />}
          <Title className={css(styles.title)}>{title}</Title>
          <Paragraph size="large" className={css(styles.paragraph)}>
            {description}
          </Paragraph>
          {!!callbackLink &&
            !!callbackText && (
            <Button className={css(styles.button)} size="huge" href={callbackLink}>
              {callbackText || 'Retour à l’accueil'}
            </Button>
          )}
          {!logo && <Image className={css(styles.image)} src="illu_thankspage_support.png" />}
        </div>
      </Wrapper>
    </Layout>
  )
}
