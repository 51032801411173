/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import {
  Title,
  Image,
  Wrapper,
  CalloutCard,
  Slider,
  SliderTab,
  Card,
  Testimonials,
  Hero,
  Showcase,
  DiscoverForm,
  Testimonial,
  Highlight,
  MinimalCalloutCard,
  Button,
} from './../../components'
import { WinoBox, Support } from './../../bundles'
import { DefaultLayout as Layout } from './../../layouts'
import { grid } from './../../theme'
import styles from './styles'

const { Row } = grid

type Props = {
  url: string,
  name: string,
  title: string,
  description: string,
}

export default function HomepageTemaplate(props: Props) {
  const { url, name, title, description } = props
  return (
    <Layout url={url} name={name} title={title} description={description}>
      <Hero
        title={title}
        content={description}
        media="illu_home_general.png"
        renderCallToAction={() => (
          <DiscoverForm
            align="center"
            lastNameInputPlaceholder="Nom"
            firstNameInputPlaceholder="Prénom"
            emailInputPlaceholder="Email"
            signUpButtonText="Demander une démo"
            signUpButtonLink="/demander-une-demo"
            signInSpanText="Déjà client ?"
            signInLinkText="Se connecter"
            signInLinkTo="https://app.wino.fr/auth/login/from-website"
          />
        )}
      />
      <Showcase className={css(styles.showcase)}>
        <Image src="logo_home_la-demi-lune.png" alt="Demi Lune" />
        <Image src="logo_home_cave-ponthieu.png" alt="Cave Ponthieu" />
        <Image src="logo_home_le-vin-sobre.png" alt="Vin Sobre" />
        <Image src="logo_home_cellier-des-docks.png" alt="Cellier des Docks" />
        <Image src="logo_home_la-cave-de-leon.png" alt="Cave de Léon" />
      </Showcase>
      <Wrapper className={css(styles.calloutCards)}>
        <CalloutCard
          label="Le logiciel idéal pour"
          title="La caisse enregistreuse et la facturation aux professionnels"
          content="Réalisez vos ventes simplement avec le logiciel de caisse sur iPad pour caviste et épicerie, ajoutez les produits au panier ou pesez les sur la balance connectée et choisissez le mode de paiement voulu. Optez pour un logiciel de facturation et de caisse certifié comme le prescrit la loi finance de 2018."
          media="illu_home_cashing.png"
          mediaSize="huge"
        />
        <CalloutCard
          direction="rtl"
          label="le logiciel idéal pour"
          title="La gestion du catalogue produit et des stocks"
          content="Gérez votre catalogue avec des fiches produits dédiées aux cavistes professionnels et épiceries (vin, fromage, vrac…). Simplifiez la gestion des stocks avec le logiciel wino : meilleur suivi des mouvements, réception de commande, seuil de stock et inventaire."
          media="illu_home_stockmanagement.png"
          mediaSize="huge"
        />
        <CalloutCard
          label="Le logiciel idéal pour"
          title="La comptabilité et le suivi des activités du caviste et épicier"
          content="Analysez vos performances commerciales telles que le chiffre d'affaires, le panier moyen, la facturation et bien plus. Exportez vos données comptables obligatoires en un clic depuis le tableau de bord Wino accessible sur tous supports."
          media="illu_home_monitoractivities.png"
          mediaSize="huge"
        />
      </Wrapper>
      <Wrapper className={css(styles.interfaces)}>
        <Slider>
          <SliderTab
            label="Encaissement en magasin"
            media="screenshot_home_cashing.png"
            alt="encaissement en magasin"
          />
          <SliderTab
            label="Gestion du catalogue produit"
            media="screenshot_home_products.png"
            alt="gestion du catalogue produit"
          />
          <SliderTab
            label="Analyse détaillée des habitudes clients"
            media="screenshot_home_clientinfo.png"
            alt="analyse détaillée des habitudes clients"
          />
          <SliderTab
            label="Gestion des ventes aux professionnels"
            media="screenshot_home_business.png"
            alt="gestion des ventes aux professionnels"
          />
          <SliderTab
            label="Rapport des performances"
            media="screenshot_home_monitoring.png"
            alt="rapport des performances"
          />
        </Slider>
      </Wrapper>
      <Wrapper>
        <div className={css(styles.tools)}>
          <Title level={2} className={css(styles.toolsTitle)}>
            6 outils centralisés dans un logiciel pour caviste et épicerie
          </Title>
          <div className={css(styles.toolsList)}>
            <Row>
              <MinimalCalloutCard
                to="/le-point-de-vente"
                taxonomy="pos"
                className={css(styles.toolsItem)}
                title="Point de vente"
                content="Gagnez du temps chaque jour pour la vente et la gestion avec"
              />
              <MinimalCalloutCard
                to="/la-fidelite"
                taxonomy="fidelity"
                className={css(styles.toolsItem)}
                title="Fidélité"
                content="Fidélisez vos clients et boostez votre chiffre d'affaires en boutique avec "
              />
              <MinimalCalloutCard
                to="/business"
                taxonomy="business"
                className={css(styles.toolsItem)}
                title="Business"
                content="Optez pour une gestion simplifiée et optimale de la facturation avec "
              />
              <MinimalCalloutCard
                to="/tableau-de-bord"
                taxonomy="dashboard"
                className={css(styles.toolsItem)}
                title="Tableau de bord"
                content="Suivez vos activités commerciales et prenez les meilleures décisions avec "
              />
              <MinimalCalloutCard
                to="/click-and-collect"
                taxonomy="potico"
                className={css(styles.toolsItem)}
                title="Potico"
                content="Vendez en ligne vos vins, spiritueux, Champagne et produits d'épicerie avec "
              />
              <MinimalCalloutCard
                to="/multiboutique"
                taxonomy="multiboutique"
                className={css(styles.toolsItem)}
                title="Multiboutique"
                content="Pilotez tous vos points de vente depuis une unique interface avec "
              />
            </Row>
          </div>
        </div>
      </Wrapper>
      <Wrapper className={css(styles.features)} size="large">
        <Title level={2}>Une solution de caisse enregistreuse complète et intuitive</Title>
        <div className={css(styles.featuresList)}>
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_pos.png"
            title="Encaissez plus rapidement"
            content="Mise en attente des tickets de caisse, remises et retours produit, balance connectée, optez pour un logiciel de caisse simple et rapide pour la vente en cave et épicerie."
          />
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_stock.png"
            title="Gérez vos stocks"
            content="Commandes fournisseurs, suivi des livraisons et inventaire rapide, gardez le contrôle des stocks pour éviter les stocks dormants, le surtock ou sous-stockage."
          />
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_business.png"
            title="Vendez aux professionnels"
            content="Envoi des factures par mail, bons de livraisons et devis, gérez vos relations professionnelles (avec des restaurants, bars, associations, etc)."
          />
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_clientfidelity.png"
            title="Fidélisez votre clientèle"
            content="Programme de fidélité sur-mesure, communication par mail, soyez plus proche de vos clients au quotidien."
          />
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_monitoring.png"
            title="Maîtrisez vos performances"
            content="Accès aux journaux de caisse, export des pièces commerciales, rapports statistiques de ventes, gagnez du temps chaque fin de mois."
          />
          <Card
            className={css(styles.featuresItem)}
            media="featurecard_home_potico.png"
            title="Vendez en ligne"
            content="Grâce à votre boutique en ligne Potico, vendez vos produits et développez votre visibilité sur une plateforme dédiée aux cavistes et épiceries fines."
          />
        </div>
        <Button size="large" to="/contact">
          Échanger avec un expert
        </Button>
        <WinoBox
          title="Un matériel de caisse enregistreuse haut de gamme"
          media="photo_home_winobox.jpg"
          content="À l'image de votre cave ou épicerie, nos experts vous conseillent dans le choix d'un matériel esthétique, robuste et de qualité (iPad, imprimante, douchette)."
          buttonText="Obtenir un devis"
          buttonLink="/devis"
        />
        <Highlight
          className={css(styles.highlight)}
          media="illu_home_newintegrations.png"
          title="Nos modules e-commerce intégrés au logiciel caisse"
          content="Dynamisez l'expérience client en magasin avec les nombreuses intégrations possibles via notre API. Vendez sur la plateforme e-commerce de votre choix grâce à nos plugins disponibles pour Prestashop, WooCommerce et Shopify."
        />
      </Wrapper>
      <Testimonials>
        <Testimonial
          name="Thomas Bravo-Maza"
          companyName="Divins"
          img="photo_home_divins.jpg"
          content="On cherchait un outil orienté pour notre métier de caviste. On sent que c’est un logiciel qui est fait par une équipe qui connait la profession. "
        />
        <Testimonial
          name="Bernard Combes"
          companyName="Le Cellier des docks"
          img="photo_home_cellier_des_docks.jpg"
          content="Nous ne sommes pas seuls, abandonnés dans notre Côte Basque, il y a une vraie proximité et disponibilité des équipes, c'est très agréable ! "
        />
        <Testimonial
          name="Christelle Taret"
          companyName="Les Caves du Parc"
          img="photo_home_caves_du_parc.jpg"
          content=" La diminution des erreurs de caisse grâce à Wino réduit le stress et nos conditions de travail sont plus agréables. L'équipe va bien et le client le ressent aussi ! "
        />
      </Testimonials>
      <Support />
    </Layout>
  )
}
