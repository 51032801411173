/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before } from 'glamor'
import { rem } from 'polished'
import { breakpoints, grid, colors, utils } from './../../theme'

const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { row, column, DEFAULT_GUTTER } = grid
const { reverseToMaxBreakpoint } = utils
const { NEUTRAL_COLOR_10 } = colors

const styles = StyleSheet.create({
  showcase: merge(
    {
      paddingBottom: rem(12),
    },
    media(MEDIUM_BREAKPOINT, {
      paddingBottom: rem(18),
    }),
    media(LARGE_BREAKPOINT, {
      paddingBottom: rem(18),
    }),
    media(HUGE_BREAKPOINT, {
      paddingBottom: rem(32),
    }),
  ),
  calloutCards: merge(
    {
      position: 'relative',
      paddingTop: rem(36),
      paddingBottom: rem(12),
    },
    before(
      merge({
        content: ' ',
        display: 'block',
        position: 'absolute',
        top: rem(18),
        width: `calc(100% + ${rem(DEFAULT_GUTTER * 2)})`,
        left: rem(-DEFAULT_GUTTER),
        height: '100%',
        borderRadius: 4,
        background: NEUTRAL_COLOR_10,
        zIndex: -1,
      }),
    ),
    media(
      MEDIUM_BREAKPOINT,
      before({
        left: '-9%',
        width: '118%',
      }),
    ),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(32),
    }),
  ),
  interfaces: merge(
    {
      display: 'none',
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'block',
      marginTop: rem(32),
      paddingTop: rem(56),
      paddingBottom: rem(56),
    }),
  ),
  features: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(88),
      marginBottom: rem(112),
    }),
  ),
  featuresList: merge(
    {
      ...row(),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(32),
    }),
  ),
  tools: merge(
    {
      marginTop: rem(84),
      marginBottom: rem(6),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(64),
      marginBottom: rem(58),
      ...row(),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(68),
      marginBottom: rem(64),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(84),
      marginBottom: rem(84),
    }),
    before(
      media(MEDIUM_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        width: `calc(100% + ${rem(DEFAULT_GUTTER * 6)})`,
        height: `calc(100% + ${rem(DEFAULT_GUTTER * 5)})`,
        top: rem(DEFAULT_GUTTER * -2),
        left: rem(DEFAULT_GUTTER * -3),
        borderRadius: 4,
        background: NEUTRAL_COLOR_10,
        zIndex: -1,
      }),
    ),
  ),
  toolsTitle: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 3),
      paddingTop: rem(40),
    }),
  ),
  toolsList: merge(
    {
      marginTop: rem(32),
    },
    media(MEDIUM_BREAKPOINT, {
      ...column(2 / 3),
    }),
  ),
  toolsItem: merge(
    {
      marginBottom: rem(DEFAULT_GUTTER),
      ...column(1),
    },
    media(SMALL_BREAKPOINT, {
      ...column(1 / 2),
    }),
  ),
  featuresItem: merge(
    column(1),
    media(SMALL_BREAKPOINT, column(1 / 2)),
    media(MEDIUM_BREAKPOINT, column(1 / 3)),
  ),
  highlight: merge(
    {
      marginTop: rem(12),
      marginBottom: rem(32),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(24),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(64),
      marginBottom: rem(-32),
    }),
  ),
  testimonials: merge(
    media(reverseToMaxBreakpoint(SMALL_BREAKPOINT), {
      height: rem(365),
    }),
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(105),
    }),
  ),
})

export default styles
