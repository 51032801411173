/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: rem(54),
    '& ul': {
      paddingLeft: 30,
      listStyle: 'initial',
    },
  }),
  title: merge(
    {
      marginTop: rem(32),
      marginBottom: rem(0),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(48),
      marginBottom: rem(0),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(64),
    }),
  ),
  content: merge(
    {
      marginTop: rem(24),
      maxWidth: rem(700),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(48),
      marginBottom: rem(24),
    }),
  ),
})

export default styles
