import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { MEDIUM_BREAKPOINT } from '../../theme/breakpoints'

export default StyleSheet.create({
  container: merge(
    {
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(80),
    }),
  ),
  title: {
    marginTop: rem(75),
  },
  button: merge(
    {
      display: 'block',
      width: '100%',
      margin: 'auto',
      marginTop: rem(5),
    },
    media(MEDIUM_BREAKPOINT, {
      width: rem(400),
    }),
  ),
  none: merge(
    {
      display: 'none',
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'inline',
    }),
  ),
  relativePost: {
    marginTop: rem(100),
  },
  textLeft: {
    textAlign: 'left',
  },
})
